<template>
  <div>
    <DeleteDefaultSlots
      v-if="slotsToDelete"
      :default-slots="slotsToDelete"
      @slotsChanged="slotsDeleted"
      @hidden="
        () => {
          slotsToDelete = null;
        }
      "
    />
    <div v-if="loading">
      <div class="d-flex justify-content-between mx-5 mt-3 mb-5">
        <div class="spinner-border text-success align-self-center loader-lg"/>
      </div>
    </div>
    <div v-else-if="loadingError" class="cm-loading-error">
      {{ $t("calendarModule.errors.loadingError") }}
    </div>
    <div v-else>
      <b-row>
        <b-col>
          <h5>{{ $t("calendarModule.massDefaultSlotsActions.heading") }}</h5>
          <p>
            {{
              $t("calendarModule.massDefaultSlotsActions.infoText").replace(
                "{amount}",
                defaultSlots.length
              )
            }}
          </p>
          <div class="mb-1">
            <b-button
              size="xs"
              class="w-100"
              :disabled="defaultSlots.length === 0"
              variant="danger"
              @click="
                () => {
                  slotsToDelete = defaultSlots;
                }
              "
            >
              {{
                $t("calendarModule.massDefaultSlotsActions.delete").replace(
                  "{amount}",
                  defaultSlots.length
                )
              }}
            </b-button>
          </div>
        </b-col>
      </b-row>
      <div class="mt-4" v-if="noList !== true">
        <h5>{{ $t("calendarModule.massDefaultSlotsActions.slotList") }}</h5>
        <div v-if="!isListShown">
          <b-button size="xs" @click="() => (forceShowList = true)">
            {{ $t("calendarModule.massDefaultSlotsActions.loadSlotList") }}
          </b-button>
        </div>
        <div v-else>
          <DefaultSlotInfo
            v-for="defaultSlot of defaultSlots"
            :key="defaultSlot._id"
            :with-edit="true"
            :the-default-slot="defaultSlot"
            @slotsChanged="slotsChanged"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as cmApi from "../../../store/modules/calendarModule/calendarModule.api";

export default {
  name: "MassDefaultSlotsActions",
  props: ["defaultSlots", "noList"],
  components: {
    DefaultSlotInfo: () =>
      import("../../../components/calendarModule/common/DefaultSlotInfo"),
    DeleteDefaultSlots: () =>
      import("../../../components/calendarModule/common/DeleteDefaultSlots")
  },
  data() {
    return {
      cmApi,
      loading: false,
      loadingError: false,
      forceShowList: false,
      slotsToMove: null,
      slotsToDelete: null,
      slotsToCancel: null
    };
  },
  computed: {
    isListShown() {
      return this.forceShowList === true || this.defaultSlots.length <= 25;
    }
  },
  methods: {
    slotsChanged() {
      this.$emit("slotsChanged");
    },
    slotsDeleted() {
      this.slotsToDelete = null;
      this.$emit("slotsChanged");
    }
  }
};
</script>
